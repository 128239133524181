import React from "react"
import ProductTable from "./ProductTable"

export default function StandardsTable() {
  const [products, setProducts] = React.useState([])

  React.useEffect(() => {
    // Fetch the product data
    const fetchData = async () => {
      const response = await fetch(
        "https://cms.simrisbiologics.com/wp-json/wp/v2/product"
      )
      const data = await response.json()
      setProducts(data)
    }

    fetchData()
  }, [])

  return (
    <div className="w-full">
      <ProductTable products={products} />
    </div>
  )
}
