import React from "react"
import HeaderFeaturedImage from "./HeaderFeaturedImage"
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import PageHeader from "./PageHeader"
import PartneringEnquiry from "./PartneringEnquiry"
import StandardsTable from "./StandardsTable"

export default function SinglePage({ data }) {
  const { content, title, featuredImage, parentDatabaseId, databaseId } =
    data.wpPage
  const img = featuredImage?.node.localFile.childImageSharp
  return (
    <div>
      {featuredImage && (
        <HeaderFeaturedImage featuredImage={featuredImage} title={title} />
      )}
      <div
        id={"page-" + databaseId}
        className="wp-page max-w-6xl min-h-screen mx-auto px-4 lg:px-8 py-8 lg:py-16"
      >
        <div className="flex flex-col gap-4 lg:gap-8">
          {!featuredImage ? <PageHeader text={title} /> : null}
          <div className="max-w-3xl  mx-auto flex flex-col xl:flex-row gap-4 lg:gap-8 w-full">
            <div className="w-full">
              <div
                dangerouslySetInnerHTML={{ __html: content }}
                className="wp-content"
              />
            </div>
          </div>
          <div className="max-w-6xl  mx-auto gap-4 lg:gap-8 w-full">
            {databaseId === 201 ? <StandardsTable /> : null}
          </div>
          {databaseId === 195 ? <PartneringEnquiry /> : null}
        </div>
      </div>
    </div>
  )
}
