import React from "react"
import { useStaticQuery, graphql } from "gatsby"
export default function PartneringEnquiry() {
  const data = useStaticQuery(graphql`
    query {
      wpPage(databaseId: { eq: 195 }) {
        id
        title
        enquiryBoxPartering {
          enquiry {
            buttonEmailEnquiry
            headline
            listEnquiry
            textBlockEnquiry
          }
        }
      }
    }
  `)
  const enquiry = data.wpPage.enquiryBoxPartering.enquiry
  const { buttonEmailEnquiry, headline, listEnquiry, textBlockEnquiry } =
    enquiry
  return (
    <>
      {enquiry ? (
        <div className="bg-primary/10 max-w-3xl mx-auto w-full">
          <div className="p-4 lg:p-8">
            <div className="flex flex-col lg:flex-row gap-4 lg:gap-8">
              <div className="flex-1 flex flex-col gap-4">
                {headline ? <h3>{headline}</h3> : null}
                {textBlockEnquiry ? (
                  <div dangerouslySetInnerHTML={{ __html: textBlockEnquiry }} />
                ) : null}
                {buttonEmailEnquiry ? (
                  <div className="hidden lg:block">
                    <a href={`mailto:${buttonEmailEnquiry}`} className="btn">
                      Enquiry
                    </a>
                  </div>
                ) : null}
              </div>
              {listEnquiry ? (
                <div className="flex-none ">
                  <div dangerouslySetInnerHTML={{ __html: listEnquiry }} />
                </div>
              ) : null}
            </div>
            {buttonEmailEnquiry ? (
              <div className="block lg:hidden">
                <a href={`mailto:${buttonEmailEnquiry}`} className="btn">
                  Enquiry
                </a>
              </div>
            ) : null}
          </div>
        </div>
      ) : null}
    </>
  )
}
